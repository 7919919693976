import { useContext } from 'react';
import { WeverCoreContext } from '@/services/core';
import { useState, useEffect } from 'react';
import SvgColorChanger from './svg-color-changer';
import { ReactComponent as Banner800 } from '@/assets/img/brand/banner800.svg'
import { ReactComponent as Banner1536 } from '@/assets/img/brand/banner1536.svg'
import { ReactComponent as Banner1920 } from '@/assets/img/brand/banner1920.svg'

const Banner = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const { theme }: any = useContext(WeverCoreContext);
  SvgColorChanger(theme.primaryColor, theme.secondaryColor, 'fillNoneStrokeColor', 'fillColor', 'fillNoneStrokeBackground', 'fillBackground');

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
  }, []);

  return (
    <>
      {windowSize <= 800 && <Banner800 className='banner-svg' />}
      {windowSize > 800 && windowSize < 1920 && <Banner1536 className='banner-svg' />}
      {windowSize >= 1920 && <Banner1920 className='banner-svg' />}
    </>
  );
};
export default Banner;
