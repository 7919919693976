import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/scss/main.scss';
import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';

import '@/assets/scss/main.scss';

import { _WeverCore as WeverCore } from '@/services/core';

import AppProvider from './contexts/main';

import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const App: React.FC = () => {
  return (
    <WeverCore editionMode locale='fr'>
      <AppProvider>
        <Outlet />
      </AppProvider>
    </WeverCore>
  );
};

export default memo(App);
