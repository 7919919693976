import { useEffect, useState } from 'react';

const useTab = () => {
  const [activeTab, setActiveTab] = useState<number>(1);

  const onChangeTabs = (e: React.MouseEvent<HTMLElement>, tab: number) => {
    e.preventDefault();
    setActiveTab(tab);

    const url = new URL(window.location as any);
    url.searchParams.set('tab', tab.toString());
    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    const url = new URL(window.location as any);
    const search_params = new URLSearchParams(url.search);
    const queryUrl = search_params.get('tab');

    if (search_params.has('tab') && queryUrl) {
      setActiveTab(parseInt(queryUrl));
    }
  }, []);

  return {
    activeTab,
    onChangeTabs,
  };
};
export default useTab;
