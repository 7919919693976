import { memo, useContext } from 'react';

import 'wever-dashboard/dist/styles/bootstrap.css';
import { _Security, WeverCoreContext } from '@/services/core';

import { useApp } from '@/contexts/main';
import DashboardComponent from './dashboard-component';
import { DashboardInterface } from '@/entities/dashboard-interface';

const Dashboard = () => {
  const { appValues: state } = useApp();

  const { locale }: DashboardInterface = useContext(WeverCoreContext);

  return (
    <>
      <DashboardComponent
        locale={locale}
        token={_Security.token}
        dashboardsId={state.dashboardsId}
        report={state.user.report}
      />
    </>
  );
};

export default memo(Dashboard);
