import { _Security as security } from '@/services/core';
import { RoutesPath } from '@/components/core/helpers';

const useLogout = () => {
  const handleLogout = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    security.deAuthenticate();
    document.location.replace(RoutesPath.Login);
  };
  return { handleLogout };
};
export default useLogout;
