import { ReactNode } from 'react';
import { Fade } from 'reactstrap';

interface ViewProps {
  children: ReactNode;
}

const View = ({ children }: ViewProps) => <Fade>{children}</Fade>;

export default View;
