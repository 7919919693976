/* eslint-disable react-hooks/exhaustive-deps */
import { useApp } from '@/contexts/main';

import { useContext } from 'react';
import { WeverCoreContext, _translate } from '@/services/core';

const ServiceReservation = () => {
  const { appValues: state } = useApp();
  const { iframeUrl } = state;
  const { dashboardIframe }: any = useContext(WeverCoreContext);

  return (
    <>
      <iframe title='Reservation' className='w-100 vh-100' src={iframeUrl ?? dashboardIframe} />
    </>
  );
};
export default ServiceReservation;
