/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from 'reactstrap';
import { memo, useState, useContext, useEffect } from 'react';

import { WeverCoreContext, _translate } from '@/services/core';
import { initialState as defaultColors } from '@/contexts/theme';
import { CommunityInterface } from '@/entities/community-interface';

import CommunitiesItem from '@/components/layout/sidebar/communities/communities-item';
import CommunitiesModal from '@/components/layout/sidebar/communities/modal-view';
import useAddCommunities from '@/components/layout/sidebar/communities/hooks/use-add-communities.hook';
import { useApp } from '@/contexts/main';

const UserCommunities = () => {
  const { appValues: state, isSideBarExpanded, dispatch } = useApp();

  const {
    loading,
    user,
    communities,
    addFormCommunity,
    deleteCommunities,
    isModalOpen,
    setisModalOpen,
  } = useAddCommunities();

  const [isCommunityActive, setIsCommunityActive] = useState<number>(0);

  const { update }: any = useContext(WeverCoreContext);

  const handleSelectCommunity = (community: CommunityInterface, index: number) => {
    setIsCommunityActive(index);
    dispatch({ type: 'selectedCommunity', value: community });
    const colors = community.colors?.primaryColor ? community.colors : defaultColors;
    update({
      theme: { ...colors, banner: community.banner, name: community.name },
      menu: community.menu,
    });
  };

  const communityModal = (
    <CommunitiesModal open={isModalOpen} setOpen={setisModalOpen} onUpdate={addFormCommunity} />
  );

  useEffect(() => {
    const selectedCommunity = state.selectedCommunity;
    if (!selectedCommunity || !state.communities) {
      return;
    }

    state.communities.forEach((community, index) => {
      if (community.id === selectedCommunity.id) {
        setIsCommunityActive(index);
      }
    });
  }, []);

  return (
    <div className='container-fluid '>
      <div className=''>
        <div className='d-none d-sm-block d-sm-none d-md-block'>
          <div className=' d-flex justify-content-between container-communities-modal-sidebar'>
            {isSideBarExpanded ? (
              <span className='communities'>{_translate('userapp_title_communities')}</span>
            ) : (
              <></>
            )}
            {communityModal}
          </div>
        </div>

        {!user || !communities || loading ? (
          <Spinner color='primary' />
        ) : (
          <div className=''>
            {state.communities &&
              state.communities.map((community, index: number) => {
                const isImg = !community.logo || community.logo == null || community.logo.includes('.');
                return (
                  <div
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSelectCommunity(community, index)}
                  >
                    <CommunitiesItem
                      dataLogo={community.logo as string}
                      isLogoMedia={!isImg}
                      classNameItemCommunity={
                        isCommunityActive === index
                          ? 'col-lg-8  isItemCommunityActive'
                          : 'col-lg-8  itemCommunity'
                      }
                      classNameImgCommunity='logoCommunitySidebar'
                      keyId={community.id}
                      ItemName={community.name ?? ''}
                      onDeleteClik={() => deleteCommunities(community.id, index)}
                      themeBorder='secondary'
                      classNameLogo={
                        isCommunityActive !== index
                          ? 'border-community-disabled '
                          : 'border-community '
                      }
                    />
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(UserCommunities);
