import { useContext } from 'react';

//@ts-ignore
import WeverDashboard from 'wever-dashboard';
import 'wever-dashboard/dist/styles/bootstrap.css';
import { _Security, WeverCoreContext } from '@/services/core';

import { useApp } from '@/contexts/main';
import { DashboardInterface } from '@/entities/dashboard-interface';

const JourneyDashboard = () => {
  const { appValues: state } = useApp();

  const { locale }: DashboardInterface = useContext(WeverCoreContext);

  return (
    <>
      <WeverDashboard
        editionMode
        locale={locale}
        token={_Security.token}
        dashboard={state.journeyDashboardId}
        report={state.user.report}
        hideCoreContext
      />
    </>
  );
};

export default JourneyDashboard;
