import React, { useState, useEffect } from 'react';
import logo from '@/assets/img/brand/icone-ronde-originale-NOIR-transparence.png';

//import Notification from '@/components/layout/navbars/menu-items/notifications';

import Languages from '@/components/layout/navbars/menu-items/languages';
import { CommunityInterface } from '@/entities/community-interface';
import { _BorderWever as BorderWever } from '@/components/core/theme';
import { useApp } from '@/contexts/main';
import SidebarResponsive from '@/components/layout/sidebar/sidebar-Offcanvas';

const NavbarMobile = () => {
  const { appValues: state } = useApp();
  const [community, setCommunity] = useState<CommunityInterface | null>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!state.selectedCommunity) {
      return;
    }

    setCommunity(state.selectedCommunity);
  }, [state.selectedCommunity]);

  if (!community) {
    return null;
  }

  return (
    <>

      <div className=' navbar-mobile d-md-none ps-3 py-2 d-flex align-items-center justify-content-between row m-0 p-0'>
        <div className="d-flex align-items-center col-9 px-0">
          <BorderWever
            theme={'secondary'}
            onClick={() => setShow(!show)}
            className='border-community ms-2'
          >
            {
              community.logo && (
                <img
                  src={community.logo}
                  className="logoCommunitySidebar"
                  alt=''
                  onError={(e) => {
                    (e.target as HTMLImageElement).src = logo;
                  }}
                />
              )
            }
          </BorderWever>
          <span className="title fw-bold ms-2">{community.name}</span>
        </div>
        <div className="col-3">
          <Languages className="navbar-language mt-0 d-flex pe-2 align-items-center" />
        </div>
      </div>
      <SidebarResponsive show={show} setShow={setShow} />
    </>
  );
};

export default NavbarMobile;
