/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import { Spinner } from 'reactstrap';

import { _Logger, WeverCoreContext, _translate, _Environment, _Translator, _Storage, _Api, _Security } from '@/services/core';

import { useApp } from '@/contexts/main';

interface LanguageProps {
  style?: object,
  className?: string
}

const Languages = ({style, className}: LanguageProps) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { locale, update }: any = useContext(WeverCoreContext);
  const {appValues: state} = useApp();

  const getLanguage = async () => {
    const currentLocal = state.user?.locale || _Storage.get('locale');
    if (typeof currentLocal === 'string') {
      await updateLocales({value: currentLocal});
    }

    try {
      const response = await fetch(`${_Environment.get('api_url')}/languages`);
      const json = await response.json();
      setOptions(
        json['hydra:member'].map((element: any) => ({
          value: element.locale,
          _id: element["@id"],
          label: _translate(element.locale),
        })),
      );
    } catch (e) {
      _Logger.error('GET LANGUAGE', '', { e });
    }
  };

  const updateLocales = async (opt: any) => {
    setLoading(true);

    await _Translator.fetch(opt.value);
    _Storage.set('locale', opt.value);

    update({ locale: opt.value });

    if (_Security.user && opt._id) {
      _Api.put(`/users/me/locale/${opt.value}`);
    }

    setLoading(false);
  };

  useEffect(() => {
    getLanguage();
  }, []);

  return (
    <div className={`mt-4 mr-2 ${className}`} style={{ minWidth: '13px',...style }}>
      <Select
        value={{ value: locale.toUpperCase(), label: _translate(locale.toUpperCase()) }}
        options={options}
        onChange={updateLocales}
        className=''
        //@ts-ignore
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? 'transparent' : 'transparent',
            border: 0,
            boxShadow: 'none',
            input: 'none',
            cursor: 'pointer',
          }),
        }}
        //@ts-ignore
        disabled={loading}
        isSearchable={false}
      />
      <br />
      {loading && <Spinner size='sm' color='warning' className='mt-3' />}
    </div>
  );
};

export default Languages;
