import { AxiosResponse } from 'axios';
import { _Api } from '@/services/core';
import { CommunityInterface } from '@/entities/community-interface';

export const getCommunityById = async (id: any): Promise<CommunityInterface | null> => {
  const response: AxiosResponse = await _Api.get(`/communities/${id}/public`);
  return response?.data as CommunityInterface | null;
};

export const getCommunityByKey = async (key: any): Promise<CommunityInterface | null> => {
  const response: AxiosResponse = await _Api.post(`/communities/private`, { key });
  return response?.data?.community as CommunityInterface | null;
};

/**
 * @todo ajouter les autres appels
 */
