/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { _Api, _getResource, _Logger, _Notifications, _translate } from '@/services/core';
import { useApp } from '@/contexts/main';
import { CommunityInterface } from '@/entities/community-interface';

const useAddCommunities = () => {
  const [isModalOpen, setisModalOpen] = useState<boolean>(false);
  const { appValues: state, dispatch } = useApp();
  const { loading, user } = state;

  const deleteCommunities = async (idCommunity: string | number, index: number) => {
    await _Api.delete(`/users/community/${idCommunity}`);

    const newCommunities = state.communities ? [...state.communities] : [];
    newCommunities.splice(index, 1);

    dispatch({ type: 'communities', value: newCommunities });
  };

  const addFormCommunity = async (communityId: string | number) => {
    let communities: CommunityInterface[] = [];

    if (state.communities) {
      communities = state.communities;
    }
    const response: AxiosResponse = await _Api.get(`${communityId}/public`);
    const community = response.data;
    communities.push(community);

    if (community) {
      dispatch({ type: 'communities', value: communities });
    }

    try {
      const userJson = {
        ...user,
        '@id': `/users/${user._id}`,
        id: user._id,
        '@context': 'Users',
        communities: [...communities.map((community) => `/communities/${community.id}`)],
      };

      await _getResource('User').update(userJson);

      dispatch({ type: 'toComplete', value: true });
    } catch (e) {
      _Logger.error('Cannot ADD community', '', { communityId, e });
      _Notifications.error(
        _translate('userapp_add_community'),
        _translate('userapp_add_community_error'),
      );
    }

    setisModalOpen(false);
  };

  return {
    loading,
    user,
    addFormCommunity,
    deleteCommunities,
    isModalOpen,
    setisModalOpen,
    communities: state.communities,
  };
};
export default useAddCommunities;
