/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, memo, createContext, useReducer } from 'react';

import { ThemeType } from '@/entities/theme-interface';

interface AppProviderProps {
  children: React.ReactElement;
}

export const initialState = {
  primaryColor: '#00aad7',
  secondaryColor: '#d73755',
  thirdColor: '#afcb37',
  banner: '',
};

type ThemeContextType = {
  theme: ThemeType;
  setTheme: React.Dispatch<ThemeType>;
};

export const ThemeContext = createContext<ThemeContextType | null>(null);

export const useTheme = () => useContext(ThemeContext) as ThemeContextType;

const appReducer = (state: ThemeType, newState: ThemeType) => {
  return newState;
};

const ThemeProvider = ({ children }: AppProviderProps) => {
  const [theme, setTheme] = useReducer(appReducer, initialState);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};

export default memo(ThemeProvider);
