import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import * as serviceWorker from '@/serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/scss/main.scss';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Routes from '@/routes2';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const router = createBrowserRouter(Routes);

root.render(
  <RouterProvider router={router} />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
