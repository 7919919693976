import Translator, { t } from 'wever-core/dist/services/translator/index';

import Api from 'wever-core/dist/services/api';
import {  Error, Label } from 'wever-core/dist/components/form';
import helper from 'wever-core/dist/components/form/select/helper';
import Context from 'wever-core/dist/services/context';
import Env from 'wever-core/dist/services/environment';
import Icon from 'wever-core/dist/components/common/icon';
import Logger from 'wever-core/dist/services/logger';
import Notif from 'wever-core/dist/services/notifications';
import Security from 'wever-core/dist/services/security';
import WeverCore from 'wever-core';
import { context } from 'wever-core/dist/services/context';
import getResource from 'wever-core/dist/services/resources';
import storage from 'wever-core/dist/services/storage';
import Modal from 'wever-core/dist/components/common/modal';

// Ce fichier permet de faire la liaison entre le wever-core et l'apps

/*
 * S'il manque quelques de wever-core, vous pourrez l'ajouter ici.
 */

// traduction => _translate('clé de traduction')
export const _translate = t;
// traduction service
export const _Translator = Translator;
// Permet d'obtenir les données api pour une entité _getResource('User').read()
export const _getResource = getResource;
// Permet de faire des appels Api "direct" en utilisant une url. (_Api.read(<url>), _Api.create(<url>, <data>))
export const _Api = Api;
// Permet de savoir si l'utilisateur est authentifié, si son role est bon etc.
export const _Security = Security;
// Permet de faire des notifications : Notif.{info, success, error, warning}
export const _Notifications = Notif;
// Permet de logger (en mode dev) dans la console. Logger.{info, success, warn, error}
export const _Logger = Logger;
// Permet de recupérer l'icone
export const _Icon = Icon;
export const _Error = Error;
export const _Label = Label;
export const _Helper = helper;
export const _Context = context;
export const _Storage = storage;
export const _Modal = Modal;
export const _WeverCore = WeverCore;

export const _Environment = Env;

export const WeverCoreContext = Context;

const DefaultObject = {
  _translate,
  _getResource,
  _Security,
  _Api,
  _Notifications,
  _Logger,
  _Translator,
  _Environment,
  WeverCoreContext,
};

export default DefaultObject;
