/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useContext, useEffect, memo, createContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { _Logger, _Api, _Security, WeverCoreContext, _Environment as Environment } from '@/services/core';
import { initialState as defaultColors } from '@/contexts/theme';
import { ThemeType } from '@/entities/theme-interface';
import { AppValuesInterface } from '@/entities/context-interface/app-values-interface';
import { AppContextInterface } from '@/entities/context-interface/app-context-interface';
import { RoutesPath } from '@/components/core/helpers';
import { CommunityInterface } from '@/entities/community-interface';
import logo from '@/assets/img/brand/icone-ronde-originale-NOIR-transparence.png';

import LoadingLayout from '@/components/layout/loading-layout';

interface AppProviderProps {
  children: React.ReactElement;
}

type Action = {
  type: string;
  value: any;
};

const initialState = {
  selectedCommunity: null,
  user: null,
  blocs: null,
  menu: null,
  communities: null,
  toComplete: null,
  diagnostics: null,
  diagnosticsToComplete: null,
  loading: undefined,
  addCommunity: null,
  iframeUrl: null
};
export const AppContext = createContext<AppContextInterface | null>(null);

export const useApp = () => useContext(AppContext) as AppContextInterface;

const appReducer = (state: AppValuesInterface, action: Action) => {
  const newState = { ...state };

  switch (action.type) {
    case 'user':
    case 'blocs':
    case 'menu':
    case 'communities':
    case 'diagnostics':
    case 'diagnosticsToComplete':
    case 'loading':
    case 'toComplete':
    case 'iframeUrl':
    case 'selectedCommunity':
      newState[action.type] = action.value;
      break;
    case 'initialize':
      return action.value;
    default:
  }

  return newState;
};

/**
 * Context pour l'évolution de l'application.
 */
const AppProvider = ({ children }: AppProviderProps) => {
  const [appValues, dispatch] = useReducer(appReducer, initialState);
  const [isConnected, setIsConnected] = useState(false);
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(true);
  const { pathname } = useLocation();
  const { update }: any = useContext(WeverCoreContext);
  const apiUrl = Environment.get('api_url');

  const navigate = useNavigate();
  const isFromLogin = pathname.indexOf('/login') !== -1;
  const isFromMagic = pathname.indexOf('/magic-redirect') !== -1;

  const setLoading = (value: boolean | undefined) => dispatch({ type: 'loading', value });

  const isAuthenticated = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const community = urlParams.get('community');
    const redirect = urlParams.get('redirect');
    const isSecurityOk = _Security && _Security.token && _Security.isAuthenticated();
    if (isSecurityOk) setIsConnected(true);

    if (!isFromLogin && !isSecurityOk) {
      if (token) {
        const communityString = community ? `&community=${community}` : '';
        const redirectString = redirect ? `&redirect=${redirect}` : '';
        navigate(`/${RoutesPath.Login}?token=${token}${communityString}${redirectString}`);
      } else {
        const redirectString = redirect ? `?redirect=${redirect}` : '';
        navigate(`/${RoutesPath.Login}${redirectString}`);
      }

      return;
    }

    if (isFromLogin && isSecurityOk) {
      navigate(`/${redirect ?? RoutesPath.HomePage}`);
      return;
    }

    if (redirect && isSecurityOk && isFromMagic) {
      navigate(`/${redirect}`);
    }

    if (!isFromLogin && isSecurityOk) {
      if (pathname === '/') {
        navigate(`/${RoutesPath.HomePage}`);
      } else {
        navigate(pathname);

      }
      return;
    }
  };

  /**
   * ***********************************************
   *  Récupération des données utilisateurs
   *  pour avoir son report, communauté, menu etc...
   * ***********************************************
   */
  const getConfig = async () => {
    try {
      const response = await _Api.get('/userapp/user/me');
      const userConfig = response.data;

      let firstCommunity = appValues.selectedCommunity;
      let isFound = false;

      const newCommunities = await Promise.all(
        userConfig.communities.map((community: CommunityInterface) => {
          const hasNotImgSelectedCommunity = !community.logo || !community.logo.includes('.')
          const newLogo = hasNotImgSelectedCommunity ? logo : `${apiUrl}/media/${community.logo}`;

          if (firstCommunity?.id === community.id) {
            firstCommunity.logo = newLogo;
            isFound = true;
          }

          return {
            ...community,
            logo: newLogo
          }
        })
      );

      firstCommunity = isFound ? firstCommunity : newCommunities[0];

      const externRedirectionFunction = { externRedirectionFunction: navigate };
      if (firstCommunity) {
        const colors: ThemeType = firstCommunity.colors?.primaryColor
          ? firstCommunity.colors
          : defaultColors;

        update({
          theme: { ...colors, banner: firstCommunity.banner, name: firstCommunity.name },
          menu: userConfig.menu,
          ...externRedirectionFunction,
        });
      } else {
        update(externRedirectionFunction);
      }

      setLoading(false);

      return {
        ...userConfig,
        communities: newCommunities,
        selectedCommunity: firstCommunity,
      };
    } catch (e) {
      _Logger.error('Catching user', '', { e });
    }
  };
  /**
   * *************************************
   *  Fonction unique qui regroupe toutes
   *  les autres
   * *************************************
   */
  const initialize = async () => {
    setLoading(true);
    const initialStateReducer = await getConfig();
    dispatch({ type: 'initialize', value: initialStateReducer });
    setLoading(false);
  };

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    initialize();
    _Logger.info('2 eme useEffect ', 'context');
  }, [isConnected]);

  useEffect(() => {
    _Logger.info('3 eme useEffect ', 'context');
    isAuthenticated();
  }, [_Security.token]);

  if (!appValues.user && !isFromLogin) {
    return <LoadingLayout />;
  }

  return (
    <AppContext.Provider value={{ appValues, dispatch, isSideBarExpanded, setIsSideBarExpanded }}>
      {children}
    </AppContext.Provider>
  );
};

export default memo(AppProvider);
