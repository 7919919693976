import { useState } from 'react';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { Spinner } from 'reactstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import logo from '@/assets/img/brand/circle-icon.svg';
import { _BorderWever as BorderWever } from '@/components/core/theme';
import { useApp } from '@/contexts/main';
import Icon from '@/components/common/icon';

interface CommunitiesItemProps {
  ItemName: string;
  onDeleteClik: () => void;
  keyId: number;
  classNameLogo?: string;
  classNameItemCommunity?: string;
  classNameImgCommunity?: string;
  onClickLogo?: (event: React.MouseEvent<HTMLElement>) => void;
  keyLogo?: number;
  dataLogo?: string;
  themeBorder: string | null;
  isLogoMedia: boolean | undefined;
}

const CommunitiesItem = ({
  ItemName,
  onDeleteClik,
  keyId,
  classNameLogo,
  onClickLogo,
  keyLogo,
  classNameItemCommunity,
  classNameImgCommunity,
  dataLogo,
  themeBorder,
  isLogoMedia,
}: CommunitiesItemProps) => {
  /**
   * change l'icone poubelle en confirm/delete
   */
  const [iconDeleteCommunities, setIconDeleteCommunities] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>();

  const { isSideBarExpanded } = useApp();

  const handleDelete = async () => {
    setLoading(true);
    await onDeleteClik();
    setLoading(false);
  };

  const removeElement = () => {
    setIconDeleteCommunities((prev) => !prev);
  };

  return (
    <div
      className='d-flex justify-content-between align-items-center mb-3'
      style={{ marginRight: '4px' }}
    >
      <BorderWever
        theme={themeBorder}
        onClick={onClickLogo}
        className={classNameLogo}
        key={keyLogo}
      >
        <img
          src={dataLogo}
          className={classNameImgCommunity}
          alt=''
          onError={(e) => {
            (e.target as HTMLImageElement).src = logo;
          }}
        />
      </BorderWever>
      {isSideBarExpanded && (
        <>
          <p key={keyId} className={classNameItemCommunity}>
            {ItemName}
          </p>
          <div className=''>
            {iconDeleteCommunities ? (
              <Icon icon={faTrashCan as IconProp} className='hasCursor' onClick={removeElement} />
            ) : (
              <div className='d-flex'>
                {loading ? (
                  <Spinner size='sm' />
                ) : (
                  <>
                    <Icon
                      icon='check'
                      className='mx-1 check-icon hasCursor'
                      onClick={handleDelete}
                    />
                    <Icon
                      icon='times'
                      className='mx-1 check-icon hasCursor'
                      onClick={removeElement}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default CommunitiesItem;
