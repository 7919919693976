import Login from 'wever-core/dist/components/security/login';
import MagicLogin from 'wever-core/dist/components/security/magic-login';

export const _Login = Login;
export const _MagicLogin = MagicLogin;

// eslint-disable-next-line
export default {
  _Login,
  _MagicLogin,
};
