import { _UserProfile as UserProfile } from '@/components/core/profile';

import useLogout from '@/hooks/use-logout-app';
import View from '@/components/view';

const Profile = () => {
  const { handleLogout } = useLogout();
  return (
    <View>
      <div className='col-12 mx-5 mt-md-5 pb-5 mb-5 pl-md-5' style={{ width: 'calc(100% - 6rem)' }}>
        <UserProfile ClickDisconnect={handleLogout} />
      </div>
    </View>
  );
};

export default Profile;
