import ReactDOMServer from 'react-dom/server';
import { Input } from 'reactstrap';

import { _translate as t } from '@/services/core';

import Icon from '@/components/common/icon';

interface CustomInputProps {
  icon?: string | any;
  placeholder: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const CustomInput = ({ icon, placeholder, onClick, ...args }: CustomInputProps | any) => {
  const placeholderTranslate = ReactDOMServer.renderToString(t(placeholder));

  return (
    <div className='d-flex align-items-center'>
      <Input {...args} placeholder={placeholderTranslate} />
      <Icon icon={icon} onClick={onClick} className='icon-login' />
    </div>
  );
};
export default CustomInput;
