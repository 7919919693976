/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// @ts-ignore
import { AxiosResponse } from 'axios';
import { RoutesPath } from '@/components/core/helpers';
import modes from '@/assets/img/login/modes.svg';
import modes2 from '@/assets/img/login/modes2.svg';
import logoWever from '@/assets/img/brand/icone-ronde-originale-NOIR-transparence.png';
import Languages from '@/components/layout/navbars/menu-items/languages';
import MagicLoginView from '../magic-login/magic-login-view';
import { getCommunityByKey } from '@/services/api-requests';
import { _Api } from '@/services/core';
import { CommunityInterface } from '@/entities/community-interface';
import { _Media as Media } from '@/components/core/common';
import { useApp } from '@/contexts/main';

import { Spinner } from 'reactstrap';

import LoginFormComponent from './login-form';

const Login = () => {
  const { key } = useParams<{ key: string }>();
  const { dispatch } = useApp();
  const [loginCommunity, setLoginCommunity] = useState<CommunityInterface | null>(null);
  const [changeMagicLogin, setChangeMagicLogin] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const ToggleMagicLogin = () => { setChangeMagicLogin(!changeMagicLogin); };

  const onSuccess = async (redirect?: string|null) => {
    if (loginCommunity) {
      const response: AxiosResponse = await _Api.get(`/communities/${loginCommunity.id}/public`);
      dispatch({ type: 'selectedCommunity', value: response.data });
    }
    console.log('redireciton', redirect);

    navigate(`/${redirect ?? RoutesPath.HomePage}`);
  }

  const handleSuccess = (redirect?: string| null) => {
    onSuccess(redirect);
  };

  const fetchData = async (communityKey: string) => {
    const result = await getCommunityByKey(communityKey);
    setLoginCommunity(result);
    setLoading(false);
  };

  useEffect(() => {
    if (!key) {
      setLoading(false);
      return;
    }

    fetchData(key);
  }, []);

  return (
    <div className='container-fluid container-login' >
      <div className='header-connexion-page'>
        {
          !loading ? (
            loginCommunity?.logo ? (
              <Media data={`${loginCommunity.logo}`} style={{ marginTop: '10px' }} height='90px' />
            ) : (
              <div className='logo-container'>
                <img src={logoWever} className='img-fluid' alt='logo wever' />
              </div>
            )
          ) : null
        }

        {!loading && <Languages />}
      </div>
      <div className='login-form-container'>
        {
          loading ? <div className="w-100 h-100 d-flex justify-content-center align-items-center"><Spinner size="sm" /></div> : (
            changeMagicLogin === true ? (
              <LoginFormComponent
                onClick={ToggleMagicLogin}
                onSuccess={handleSuccess}
                primaryColor={loginCommunity?.colors?.primaryColor}
              />
            ) : (
              <MagicLoginView onClick={ToggleMagicLogin} primaryColor={loginCommunity?.colors?.primaryColor} />
            )
          )
        }
      </div>
      <div className='col-9 position-absolute bottom-0 start-50 translate-middle-x '>
        <img
          src={loginCommunity ? modes2 : modes}
          className='img-fluid'
          alt='circulation de véhicules'
        />
      </div>
    </div>
  );
}

export default Login;
