import { useState } from 'react';
// @ts-ignore
import { AxiosResponse } from 'axios';
import { useApp } from '@/contexts/main';

import { _Api } from '@/services/core';

import { useNavigate } from "react-router-dom";

import {
  _Notifications as notifications,
  _Security as security,
  _translate as t,
  _Api as api
} from '@/services/core';
import { LoginFormProps } from '@/entities/login-props-interface';

const useLogin = ({ onSuccess, onFailed }: LoginFormProps) => {
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState(true);
  const {dispatch} = useApp();
  const navigate = useNavigate();

  const togglePassword = () => {
    setPasswordType(!passwordType);
  };

  const onSubmit = async ({ username, password }: { username: string; password: string }) => {
    setLoading(true);

    try {
      const { data }: AxiosResponse<{ refresh_token: string; token: string; success: true }> = await security.login(
        username,
        password,
      );

      if (typeof onSuccess === 'function') {
        if (false === onSuccess()) {
          setLoading(false);
        }
      }
    } catch (error) {
      if (error instanceof Response && error.status === 401) {
        notifications.error(t('common_error_title'), t('common_error_invalid_credentials'));
      }

      if (typeof onFailed === 'function') {
        onFailed(error);
      }

      setLoading(false);
    }
  };

  const connectWithToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token'); // wever-user-app.local/magic-redirect?token=ab253012153a5cc&community=60
    const community = urlParams.get('community'); // wever-user-app.local/magic-redirect?token=dsqdlqsdlsqkl&redirect=diagnostic
    const redirect = urlParams.get('redirect'); // wever-user-app.local/magic-redirect?token=sdqsdsqdqsdqs&redirect=diagnostics

    if (!token) {
      return;
    }
    setLoading(true);

    try {
      const {
        data,
      }: AxiosResponse<{
        refresh_token: string;
        token: string;
        success: true;
      }> = await api.post('/users/magic_token', { magicToken: token });

      if (!data) {
        setLoading(false);
        return;
      }

      security.authenticate(data.token, data.refresh_token);

      setLoading(false);
      if (typeof onSuccess === 'function') {
        if (community) {
          try {
            const response: AxiosResponse = await _Api.get(`/communities/${community}/public`)
            dispatch({ type: 'selectedCommunity', value: response.data });
          } catch (e) {
            console.error('Unknow community');
          }
        }

        onSuccess(redirect);
      }
    } catch (e) {
      if (typeof onFailed === 'function') {
        onFailed(e);
      }
      setLoading(false);
    }
  };

  const customizeForCommunity = () => {
    
  }

  return { loading, onSubmit, togglePassword, passwordType, connectWithToken, customizeForCommunity };
};
export default useLogin;
