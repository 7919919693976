import { Dispatch, SetStateAction } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { _translate as t, _translate } from '@/services/core';
import Communities from './communities/communities';
import useAddCommunities from './communities/hooks/use-add-communities.hook';
import CommunitiesModal from './communities/modal-view';
import poweredBy from '@/assets/img/icons/common/powered-by-vertical.png';

interface SidebarOffcansavProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const SidebarResponsive = ({ show, setShow }: SidebarOffcansavProps) => {
  const handleShow = () => setShow(!show);

  const { isModalOpen, setisModalOpen, addFormCommunity } =
    useAddCommunities();
  ;

  if (!show) {
    return null;
  }

  return (
    <Offcanvas show={show} onHide={handleShow} responsive='lg' className='widthOffcanvas '>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {t('userapp_title_offcanvas')}
          <span className='pinkPeriod'>.</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Communities />
        <hr />
        <div className=' d-flex justify-content-between container-communities-modal-sidebar'>
          <span className='communities d-flex'>
            <CommunitiesModal
              open={isModalOpen}
              setOpen={setisModalOpen}
              onUpdate={addFormCommunity}
            />
            <span className='mx-2 mt-1'>{_translate('userapp_title_communities')}</span>
          </span>
        </div>
      </Offcanvas.Body>
      <span className='poweredByWeverPart'>
        <img alt='powered by wever' src={poweredBy} />
      </span>
    </Offcanvas>
  );
};

export default SidebarResponsive;
