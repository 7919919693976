import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { _Security, WeverCoreContext } from '@/services/core';

// @ts-ignore
import Wepool from 'wever-pool-component';
import 'wever-pool-component/dist/styles/bootstrap.css';
import { DashboardInterface } from '@/entities/dashboard-interface';

const WepoolCarpooling = () => {
  const { locale }: DashboardInterface = useContext(WeverCoreContext);
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Wepool token={_Security.token} locale={locale} isMultilang={false} editionMode diagId={id} />
    </>
  );
};
export default WepoolCarpooling;
