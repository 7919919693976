export const RoutesPath = {
  Init: '/',
  Login: 'login',
  Magic: 'magic',
  MagicRedirect: 'magic-redirect',
  Profile: 'profile',
  DiagnosticsTabs: 'diagnostics',
  Wepool: 'wepool',
  PageDashboard: 'dashboard',
  ServiceReservation: 'booking',
  HomePage: 'dashboards',
  JourneyDashboard: 'journey',
  Collector: 'diagnostics/:id',
  CommunityKeyLogin: 'login/:key',
};

export const AppRoles = {
  User: 'ROLE_USER',
  SuperAdmin: 'ROLE_SUPER_ADMIN',
  Admin: 'ROLE_ADMIN',
  AccountManager: 'ROLE_ACCOUNT_MANAGER',
  Manager: 'ROLE_MANAGER',
  Anonymous: 'IS_AUTHENTICATED_ANONYMOUSLY',
  TradDiagnostic: 'ROLE_TRAD_DIAGNOSTIC',
  TeamWever: 'ROLE_TEAM_WEVER',
  Provider: 'ROLE_PROVIDER',
};
