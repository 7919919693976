/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { redirect, useLocation, Outlet } from 'react-router-dom';
import { AppRoles, RoutesPath } from '@/components/core/helpers';
import {
  _translate as t,
  _Security as security,
  _Notifications as notifications,
  _Modal as Modal,
} from '@/services/core';
import LoadingLayout from '@/components/layout/loading-layout';
import AdminNavbar from '@/components/layout/navbars/admin-navbar';
import UserSidebar from '@/components/layout/sidebar/sidebar';
import { useApp } from '@/contexts/main';

export interface AdminProps {
  routes: () => void;
}

const Layout = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { isSideBarExpanded } = useApp();

  const initiliaze = useCallback(async () => {
    if (!security.isGranted(AppRoles.User)) {
      notifications.warning(t('userapp_login'), t('userapp_not_authorized'));
      security.deAuthenticate();
      redirect(RoutesPath.Login);
      return;
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    initiliaze();
  }, [security.user]);

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth < 769);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll top when user navigates between different pages
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (isLoading) {
    return <LoadingLayout />;
  }

  return (
    <>
      <Modal />
      <div className='h-100'>
        <div className='sidebar position-fixed top-0 left-0 overflow-visible'>
          <UserSidebar />
        </div>
        <div 
          className='p-0 pb-2 paddingB-layout'
          style={
            isSmallScreen
              ? { width: '100%', marginLeft: '0px' }
              : isSideBarExpanded
                ? { width: 'calc(100% - 270px)', marginLeft: '270px' }
                : { width: 'calc(100% - 55px)', marginLeft: '55px' }
          }
        >
          <AdminNavbar />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
