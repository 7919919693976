import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
interface IconProps {
  icon: IconProp | null;
  name?: string;
  onClick?: any;
  className?: string;
  id?: string;
}

const Icon = ({ icon, name, onClick, className, id }: IconProps) => {
  if (!icon) return null;

  return <FontAwesomeIcon icon={icon} name={name} onClick={onClick} className={className} id={id} />;
};
export default Icon;
