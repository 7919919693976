import BorderWever from 'wever-core/dist/components/common/theme-primary/border-wever';
import ButtonWever from 'wever-core/dist/components/common/theme-primary/button-wever';
import TextWever from 'wever-core/dist/components/common/theme-primary/text-wever';

export const _BorderWever = BorderWever;
export const _ButtonWever = ButtonWever;
export const _TextWever = TextWever;

export default {
  _BorderWever,
  _ButtonWever,
  _TextWever,
};
