/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useApp } from '@/contexts/main';
import { DiagnosticInterface } from '@/entities/diagnostic-interface';
import { _Api } from '@/services/core';

const useDiagnostics = () => {
  const { appValues: state, dispatch } = useApp();
  const [loading, setLoading] = useState<boolean>(true);

  const getCompletedDiagnostics = async () => {
    setLoading(true);
    const response = await _Api.get(`/diagnostics/me/completed?community=${state.selectedCommunity?.id}`);
    dispatch({
      type: 'diagnostics',
      value: response.data['diagnostics'] || (null as DiagnosticInterface[] | null),
    });
    setLoading(false);
  };

  useEffect(() => {
    getCompletedDiagnostics();
  }, [state.selectedCommunity?.id]);

  const getDiagnostics = async () => {
    setLoading(true);
    const response = await _Api.get(`/diagnostics/me/to_complete?community=${state.selectedCommunity?.id}`);

    dispatch({
      type: 'diagnosticsToComplete',
      value: response.data.diagnostics || (null as DiagnosticInterface[] | null),
    });
    setLoading(false);
  };

  useEffect(() => {
    getDiagnostics();
  }, [state.selectedCommunity?.id]);
  return {
    loading,
  };
};
export default useDiagnostics;
