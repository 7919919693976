import { NavLink, useLocation } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { useApp } from '@/contexts/main';

interface MenuItemProps {
  navLinkTo: string;
  title: string;
  srcImg?: string;
  onClick?: () => void;
  itemIndex?: number;
}

const MenuItem = ({ navLinkTo, title, srcImg, onClick, itemIndex }: MenuItemProps) => {
  const { isSideBarExpanded } = useApp();
  const { pathname } = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const index = urlParams.get('index');
  return (
    <Nav vertical onClick={() => onClick?.()}>
      <NavItem className='sidebar-item my-3'>
        <NavLink
          to={navLinkTo}
          className={({ isActive }) =>
            isActive && Boolean(index ? itemIndex?.toString() === index : pathname === `/${navLinkTo}`) ? 'active' : 'sidebar-link'
          }
        >
          <span className='pipe-left' />
          <img src={srcImg} alt='icon error' className='icon' />
          {isSideBarExpanded && title}
        </NavLink>
      </NavItem>
    </Nav>
  );
};
export default MenuItem;
