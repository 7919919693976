import { faCircleUser as falCircleUser } from '@fortawesome/free-regular-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

import { _Icon as Icon, _translate as t, _Security } from '@/services/core';
import { RoutesPath } from '@/components/core/helpers';
import useLogout from '@/hooks/use-logout-app';

const Account = () => {
  const { handleLogout } = useLogout();

  return (
      <Dropdown >
        <Dropdown.Toggle
          variant='transparent'
          id='dropdown-basic'
          className='d-flex account-title   p-0 align-items-center'
        >
          <Icon name={falCircleUser} className='icon-navbar  mx-1 icon-mobile' />{' '}
          <div className="d-flex flex-column justify-content-center align-items-start">
            <span className='mx-1  '>{t('my_account')}</span>
            <span className="mx-1 account-username-title">{_Security.user?.username}</span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className=''>
          <Dropdown.Item href=''>
            <Link to={RoutesPath.Profile} className='link-none'>
              {t('userapp_account')}
            </Link>
          </Dropdown.Item>
          <Dropdown.Item href='' onClick={handleLogout}>
            <span className=''>{t('userapp_logout')}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
  );
};
export default Account;
