import { memo } from 'react';

//@ts-ignore
import WeverDashboard from 'wever-dashboard';

import { DashboardInterface } from '@/entities/dashboard-interface';

const DashboardComponent = ({ token, locale, dashboardsId, report }: DashboardInterface) => {
  return (
    <WeverDashboard
      editionMode
      locale={locale}
      token={token}
      dashboard={dashboardsId}
      report={report}
      hideCoreContext
    />
  );
};
export default memo(DashboardComponent);
