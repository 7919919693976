import { _MagicLogin as MagicLogin } from '@/components/core/security';
import { _translate as t } from '@/services/core';

interface MagicLoginFormProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  primaryColor?: string;
  community?: string
}

const MagicLoginView = ({ onClick, primaryColor, community }: MagicLoginFormProps) => {
  return (
    <>
      <div className='m-auto  login-form-contenu '>
        <p className='fs-title-login col-9 m-auto '>{t('userapp_connect')}</p>
        <p className='fs-text-magic-login col-10 m-auto pt-3 pb-3'>{t('magic_token_subtitle')}</p>

        <div className='mb-1 mb-sm-0 m-auto col-10  col-sm-10'>
          <span className='magic-login'>
            <MagicLogin className={primaryColor ? 'btn-form rounded-3 text-size-btn' : null} style={primaryColor ? {'backgroundColor': primaryColor, 'padding': "2px 0px"} : null} community={community} />
          </span>
        </div>
      </div>
      <div className='text-center my-2 my-sm-1 mt-lg-3'>
        <span className='fs-magic-link' onClick={onClick}>
          {t('back_to_login')}
        </span>
      </div>
    </>
  )
}
  ;

export default MagicLoginView;
