import { useEffect } from 'react';

const SvgColorChanger = (backgroundColor: string, secondary: string, backgroundStrokeClass: string, backgroundFillClass: string, designStrokeClass: string, designFillClass: string) => {
  useEffect(() => {
    Array.from(
      document.getElementsByClassName('rect-st0',
      ) as HTMLCollectionOf<HTMLElement>,
    ).forEach((e) => {
      e.style.fill = backgroundColor;
    });
    Array.from(
      document.getElementsByClassName('rect-st5',
      ) as HTMLCollectionOf<HTMLElement>,
    ).forEach((e) => {
      e.style.stroke = backgroundColor;
    });
    Array.from(
      document.getElementsByClassName('inverted-card-faPen',
      ) as HTMLCollectionOf<HTMLElement>,
    ).forEach((e) => {
      e.style.backgroundColor = secondary;
    });
    Array.from(
      document.getElementsByClassName('card-faPen',
      ) as HTMLCollectionOf<HTMLElement>,
    ).forEach((e) => {
      e.style.color = secondary;
    });
  });
};

export default SvgColorChanger;
