import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { _Api, _Notifications, _translate } from '@/services/core';
import { CommunityInterface } from '@/entities/community-interface';
import { useApp } from '@/contexts/main';

const useModal = (
  onUpdate?: (value: string) => void,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const [selectValue, setSelectValue] = useState<{ value: number | string } | null>();
  const [inputPrivateCommunityKey, setInputPrivateCommunityKey] = useState<number | string>();
  const [loading, setLoading] = useState<boolean>(false);

  const { appValues: state } = useApp();
  const toggle = () => setOpen?.(false);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputPrivateCommunityKey(e.target.value);
  }

  const loadPrivateCommunity = async (key: number | string) => {
    setLoading(true);
    const response: AxiosResponse = await _Api.post(`/communities/private`, { key });
    setLoading(false);
    setInputPrivateCommunityKey('');

    return response.data?.community as CommunityInterface;
  };

  const handleSaveCommunity = async () => {
    setLoading(true);

    if (selectValue && selectValue.value) {
      onUpdate?.(selectValue.value['@id']);
      setSelectValue(null);
      setLoading(false);
      return;
    }

    if (inputPrivateCommunityKey) {
      const response = await loadPrivateCommunity(inputPrivateCommunityKey);

      if (!response) {
        _Notifications.error(
          _translate('userapp_add_community'),
          _translate('userapp_add_community_key_not_found'),
        );
        setLoading(false);
        return;
      }

      if (state.communities?.map((el) => el.id).indexOf(response.id) === -1) {
        onUpdate?.(`/communities/${response.id}`);
        setLoading(false);
        return;
      }

      _Notifications.error(
        _translate('userapp_add_community'),
        _translate('userapp_add_community_already_mapped'),
      );
      setLoading(false);
      return;
    }

    _Notifications.error(
      _translate('userapp_add_community'),
      _translate('userapp_add_community_need_one'),
    );
    setLoading(false);
  };

  return { handleSaveCommunity, handleChange, inputPrivateCommunityKey, loading, toggle };
};

export default useModal;
