/** eslint-disable-file */
import { RouteObject } from 'react-router-dom';

import { RoutesPath } from '@/components/core/helpers';

import App from './app';
import Admin from '@/components/layout/layout';
import Login from '@/views/login/login';
import WepoolCarpooling from './views/wepool/wepool-carpooling';
import Dashboard from './views/dashboard/dashboard';
import MagicLoginView from './views/magic-login/magic-login-view';
import Profile from './views/profile/profile';
import ServiceReservation from './views/service-reservation/service-reservation';
import Collector from './views/diagnostic-tabs/diagnostic/collector';
import Diagnostics from '@/views/diagnostic-tabs/diagnostics';
import PageDashboard from '@/views/page-dashboard/dashboard';
import JourneyDashboard from './views/dashboard/dashboard-journey';

const Loading = () => {
  return <div>Loading</div>;
};

const Error404 = () => {
  return <div>ERROR</div>;
};

const Routes: RouteObject[] = [
  {
    path: '',
    element: <App />,
    loader: () => <Loading />,
    errorElement: <Error404 />,
    children: [
      {
        path: RoutesPath.Login,
        element: <Login />,
        errorElement: <Error404 />,
      },
      {
        path: RoutesPath.MagicRedirect,
        element: <Login />,
        errorElement: <Error404 />,
      },
      {
        path: RoutesPath.CommunityKeyLogin,
        element: <Login />,
        errorElement: <Error404 />,
      },
      {
        path: RoutesPath.Magic,
        element: <MagicLoginView />,
        errorElement: <Error404 />,
        children: [],
      },
      {
        path: '',
        element: <Admin />,
        loader: () => <Loading />,
        errorElement: <Error404 />,
        children: [
          {
            path: RoutesPath.HomePage,
            element: <Dashboard />,
          },
          {
            path: RoutesPath.JourneyDashboard,
            element: <JourneyDashboard />,
          },
          {
            path: RoutesPath.DiagnosticsTabs,
            element: <Diagnostics />,
          },
          {
            path: RoutesPath.Wepool,
            element: <WepoolCarpooling />,
          },
          {
            path: RoutesPath.PageDashboard,
            element: <PageDashboard />,
          },
          {
            path: RoutesPath.ServiceReservation,
            element: <ServiceReservation />,
          },
          {
            path: RoutesPath.Profile,
            element: <Profile />,
          },
          {
            path: RoutesPath.Collector,
            element: <Collector />,
          },
        ],
      },
    ],
  },
];

export default Routes;
