import { useLocation } from 'react-router-dom';
import { useContext, useState } from 'react';

import React from 'react';
import { _translate as t } from '@/services/core';
import { RoutesPath } from '@/components/core/helpers';
import { WeverCoreContext } from '@/services/core';

import Account from '@/components/layout/navbars/menu-items/account';
import Languages from '@/components/layout/navbars/menu-items/languages';
//import Notification from '@/components/layout/navbars/menu-items/notifications';
import NavbarMobile from './navbar-mobile';
import NavbarMobileBottom from './navbar-mobile-bottom';

import Banner from '@/components/common/banner/banner';

const AdminNavbar = () => {
  const { theme }: any = useContext(WeverCoreContext);
  const { pathname } = useLocation();
 
  const displayBanner = pathname.indexOf('diagnostics/') === -1;

  return (
    <>
      <div className='navbar-desktop d-lg-block d-md-block border-bottom'>
        <div className='d-flex mx-4 justify-content-between align-items-center'>
          <div className='nav-item nav-title d-flex align-items-center'>
            {pathname === `/${RoutesPath.Wepool}` ? (
             t('userapp-welcome-co-sharing-wepool') 
            ) : (
              <>
                {t('userapp-welcome-participatory-mobility-operation')} {''}
                {theme.name === 'wever' || pathname === `/${RoutesPath.Profile}` ? '' : theme.name}
              </>
            )}
          </div>
          <div className='d-flex align-items-center'>
            <div className='nav-item d-flex align-items-start pt-2'>
              <Languages className="navbar-language"/>
            </div>
            <div className='nav-item d-flex align-items-center d-flex align-items-center'>
              <Account />
            </div>
          </div>
        </div>
      </div>
      <NavbarMobile  />
      <NavbarMobileBottom />
      {displayBanner && <Banner />}
    </>
  );
};

export default AdminNavbar;
