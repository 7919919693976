import { faCircleUser as falCircleUser } from '@fortawesome/free-regular-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

import { RoutesPath } from '@/components/core/helpers';
import { _Icon as Icon } from '@/services/core';

import crosshairs from '@/assets/img/icons/common/location-crosshairs-solid.svg';
import { useApp } from '@/contexts/main';

const NavbarMobile = () => {
    const { appValues: state } = useApp();

    return (
        <div className='navbar-mobile d-md-none'>
            <nav className='navbar fixed-bottom navbar-light bg-light'>
                <div className='container-fluid'>
                    <NavLink to={RoutesPath.DiagnosticsTabs} className='icon-mobile'>
                        <Icon name={faHome} />
                    </NavLink>
                    {Array.isArray(state?.menu) &&
                        state.menu?.map((item: any, index: number) => (
                            <NavLink to={item.path} className='icon-mobile' key={index}>
                                <img src={item.icon} alt='icon error' className='icon' />
                            </NavLink>
                        ))}
                    {Array.isArray(state?.selectedCommunity?.menu) &&
                        state.selectedCommunity?.menu.map((item: any, index: number) => (
                            <NavLink to={item.path} className='icon-mobile' key={index}>
                                <img src={item.icon} alt='icon error' className='icon' />
                            </NavLink>
                        ))}
                    <NavLink to={RoutesPath.JourneyDashboard} className='icon-mobile'>
                        <img src={crosshairs} alt='icon error' className='icon' />
                    </NavLink>
                    <NavLink to={RoutesPath.Profile} className='icon-mobile'>
                        <Icon name={falCircleUser} />
                    </NavLink>
                </div>
            </nav>
        </div>
    );
};

export default NavbarMobile;
